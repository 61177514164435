import React, {  } from "react";
import "./Page.scss"

interface Props {
  id: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  contentSize?: "medium" | "full" | "small";
  ignoreTitle?:boolean
}
export let Page: React.FC<Props> = ({
  id,
  children,
  footer,
  contentSize = "medium",
  ignoreTitle=false
}) => {
  return (
    <div className={"page " + id+"-page"} id={id}>
      <div className={"page-inner " + contentSize}>
        {ignoreTitle !== true && (
          <div style={{ height: "calc(90px + 4vmin)" }}></div>
        )}
        {children}
      </div>
      {footer}
    </div>
  );
};
