import React, { useCallback, useEffect, useState } from "react";
import "./App.scss";
import { Page } from "./components/Page";
import { Menu } from "./components/Menu";
import { Impressum } from "./pages/Impressum";
import { Exhibition } from "./pages/Exhibition";
import { Info } from "./pages/Info";
import { Header } from "./components/Header";
import { PageDivider } from "./components/PageDivider";
import { Map } from "./pages/Map";
import { ReactComponent as NextPageIcon } from "./icons/nextpage.svg";
import { MenuPopup } from "./components/MenuPopup";
import { Sponsors } from "./pages/Sponsors";

interface PProps {
  list: string[];
  idx: number;
  divider?: boolean;
  children?: React.ReactNode;
  ignoreTitle?: boolean;
}
let PageWrapper: React.FC<PProps> = ({
  list,
  idx,
  children,
  divider = true,
  ignoreTitle = false,
}) => {
  return (
    <>
      {divider && <PageDivider></PageDivider>}
      <Page
        // prev={idx > 0 ? list[idx - 1] : undefined}
        id={list[idx]}
        // next={idx < list.length ? list[idx + 1] : undefined}
        contentSize={"full"}
        ignoreTitle={ignoreTitle}
      >
        {children}
      </Page>
    </>
  );
};
let App: React.FC = () => {
  let [rerender, forceRender] = useState(false);
  let pages: { [id: string]: string } = {
    start: "Startseite",
    info: "Rahmenprogramm",
    exhibition: "Ausstellungen",
    map: "Karte",
  };
  let fullpages = {
    ...pages,
    // sponsors: "Sponsor:innen",
    impressum: "Impressum",
  };
  // let [active, setActive] = useState<string | undefined>(pages[0]);
  useEffect(() => {
    if (
      rerender === false &&
      window.location.hash !== "" &&
      window.location.hash !== "start"
    ) {
      forceRender(!rerender);
    }
  }, [forceRender, rerender]);
  let pageIDs = Object.keys(fullpages);
  let sidePageIDs = Object.keys(pages);
  // let onLocationChange = useCallback(() => {
  //   setActive(
  //     Object.keys(pages).find((e) => window.location.href.includes("#" + e))
  //   );
  // }, []);
  let onResizeChange = useCallback(() => {
    window.location.assign(window.location.hash);
  }, []);
  useEffect(() => {
    // window.addEventListener("hashchange", onLocationChange);
    window.addEventListener("resize", onResizeChange);
    return () => {
      // window.removeEventListener("hashchange", onLocationChange);
      window.removeEventListener("resize", onResizeChange);
    };
  }, [onResizeChange]);

  // Safari bugfix
  useEffect(() => {
    function preventDefault(e: Event) {
      e.preventDefault();
    }
    function stopPropagation(e: Event) {
      e.stopPropagation();
    }
    function preventDefaultForScrollKeys(e: KeyboardEvent) {
      let keys:any = { 37: 1, 38: 1, 39: 1, 40: 1 };
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    }

    // modern Chrome requires { passive: false } when adding event
    let supportsPassive = false;
    try {
      window.addEventListener(
        "test",
        () => {},
        Object.defineProperty({}, "passive", {
          get: function () {
            supportsPassive = true;
          },
        })
      );
    } catch (e) {}
    let root=document.querySelector("#root")
    let wheelOpt = supportsPassive ? { passive: false } : false;
    let wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";
    // call this to Disable
    document.body.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
    document.body.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    document.body.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
    document.body.addEventListener(
      "keydown",
      preventDefaultForScrollKeys,
      false
    );
    root?.addEventListener("DOMMouseScroll", stopPropagation, false); // older FF
    root?.addEventListener(wheelEvent, stopPropagation, wheelOpt); // modern desktop
    root?.addEventListener("touchmove", stopPropagation, wheelOpt); // mobile
    root?.addEventListener("keydown", stopPropagation, false);


    return () => {
    document.body.removeEventListener("DOMMouseScroll", preventDefault, false);
      document.body.removeEventListener(wheelEvent, preventDefault);
      document.body.removeEventListener("touchmove", preventDefault);
      document.body.removeEventListener(
        "keydown",
        preventDefaultForScrollKeys,
        false
      );
      
    root?.removeEventListener("DOMMouseScroll", stopPropagation, false); // older FF
    root?.removeEventListener(wheelEvent, stopPropagation); // modern desktop
    root?.removeEventListener("touchmove", stopPropagation); // mobile
    root?.removeEventListener("keydown", stopPropagation, false);
    };
  });
  return (
    <div className="app">
      <Header entries={fullpages} />
      <MenuPopup entries={fullpages} startpage></MenuPopup>
      <Menu entries={sidePageIDs}></Menu>

      <PageWrapper list={pageIDs} idx={0} divider={false}>
        <a className="next-page" href="#info">
          <NextPageIcon></NextPageIcon>
        </a>
      </PageWrapper>
      <PageWrapper list={pageIDs} idx={1}>
        <Info />
      </PageWrapper>

      <PageWrapper list={pageIDs} idx={2}>
        <Exhibition />
        {/* <a className="next-page" href="#map">
          <NextPageIcon></NextPageIcon>
        </a> */}
      </PageWrapper>

      <PageWrapper list={pageIDs} idx={3} ignoreTitle={true}>
        <Map></Map>
      </PageWrapper>

      {/* <PageWrapper list={pageIDs} idx={4}>
        <Sponsors></Sponsors>
      </PageWrapper> */}

      <PageWrapper list={pageIDs} idx={4} divider={false}>
        <Impressum />
      </PageWrapper>
    </div>
  );
};

export default App;
