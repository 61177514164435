import "./PageContent.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  row?: boolean;
}
export let PageContent: React.FC<Props> = ({
  children,
  className,
  row = true,
}) => {
  return (
    <div
      className={
        "page-content " +
        (row ? "row " : "column ") +
        (className ? className : "")
      }
    >
      {children}
      <div style={{height:"50px"}}></div>
    </div>
  );
};
