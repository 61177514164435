import "./Footer.scss"

export let Footer: React.FC = () => {
  return (
    <div className="footer">
      <a href="#impressum" style={{ pointerEvents: "all" }}>
        Impressum
      </a>
    </div>
  );
};