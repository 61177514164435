import { Card } from "../components/Card";
import { PageContent } from "../components/PageContent";
import { PageTitle } from "../components/PageTitle";
import "./Info.scss";

let dates: {
  [day: string]: {
    time: string;
    place: string;
    description: string;
    website?: string;
  }[];
} = {
  Samstag: [
    {
      time: "7.00 - 17.00",
      place: "Burglachbergstraße 13, Altenstadt",
      description:
        "Backstube mit Herz - Flammkuchen, diverse Torten und Snacks",
      website: "https://backstube-mit-herz.de",
    },
    // {
    //   time: "11.00 - 18.00",
    //   place: "Goldrausch Altenstadt",
    //   description: "Drinks, Kunst und DJ",
    // },
    // {
    //   time: "11.00 - 18.00",
    //   description: "Getränke bei Restaurant Piroska",
    //   place: "Restaurant Piroska",
    // },
    {
      time: "12.00 - 18.00",
      place: "Brücke an der Kirche",
      description: "Würschtl-Bude der FFW und Landjugend Schwabbruck",
    },
    {
      time: "14.00 - 18.00",
      place: "Zur Pfannenschmiede 2a Schwabbruck",
      description: "Café und Auszogene bei Endt's",
    },
    {
      time: "Ab 16.00",
      place: "KulZe Schwabniederhofen",
      description: "Open Studio / Offene Jam-Session",
    },
    {
      time: "Ab 18.00 (Open End)",
      place: "KulZe Schwabniederhofen",
      description:
        "Musikveranstaltung mit Tonic Walter, David Gramberg, Spindler, Nomad Soundsystem, Furchtzwerg",
    },
  ],
  Sonntag: [
    {
      time: "7.00 - 17.00",
      place: "Burglachbergstraße 13, Altenstadt",
      description:
        "Backstube mit Herz - Flammkuchen, diverse Torten und Snacks",
      website: "https://backstube-mit-herz.de",
    },
    {
      time: "11.00 - 16.00",
      place: "Pröbstl-Mühle Schwabsoien",
      description: "Gesichtsmalerei mit Anke Firlefanz",
    },
    // {
    //   time: "11.00 - 18.00",
    //   place: "Goldrausch Altenstadt",
    //   description: "Drinks und Kunst",
    // },
    // {
    //   time: "11.00 - 18.00",
    //   description: "Getränke bei Restaurant Piroska",
    //   place: "Restaurant Piroska",
    // },
    {
      time: "12.00 - 18.00",
      place: "Brücke an der Kirche",
      description: "Würschtl-Bude der FFW und Landjugend Schwabbruck",
    },
    {
      time: "14.00 - 18.00",
      place: "Zur Pfannenschmiede 2a Schwabbruck",
      description: "Café und Auszogene bei Endt's",
    },

    {
      time: "17.00",
      place: "Zur Pfannenschmiede 2a Schwabbruck",
      description: 'Band "Tain" am Bach',
    },
  ],
};
export let Info: React.FC = () => {
  return (
    <>
      <PageTitle>Rahmenprogramm</PageTitle>
      <PageContent>
        <Card className="info-card">
          <div className="info-text">
            Brücken sind ein Symbol für unsere Fähigkeit, miteinander und mit
            der Welt in Verbindung zu treten. Brücken werden buchstäblich
            zwischen den Menschen der Region gebaut. Die Kunstausstellung am 3.
            und 4. September 2022 soll daher mit dem Thema Brücken Grenzen
            überwinden und frischen Wind ins Auerbergland bringen. <br />
            Die Ausstellung „mit Kunst über-Brücken“ umfasst alle Gemeinden an
            der Schönach und kann in einer Radtour entlang des Baches erkundet
            werden. Künstler:innen und Kreative aus der Region stellen ihre
            Werke sowohl im Innen-, als auch im Außenraum - an, auf und unter
            Brücken - aus. Dazu gibt es ein musikalisches Programm und zahlreiche
            Stationen, an denen die Besucher:innen sich einen Schmaus hinter die
            Kiemen zimmern können. Eine Symbiose aus Kunst- und Natur-Erlebnis
            entlang der Schönach. <br /> <b>Schau vorbei!</b>
          </div>
          <div className="schedule">
            {Object.entries(dates).map(([day, info]) => (
              <div key={day}>
                <h2>{day}</h2>
                <table>
                  <tbody>
                    {info.map((i, idx) => (
                      // <tr key={"el"+idx}>
                      //   <td>{i.time}</td>
                      //   <td>{i.description}</td>
                      //   <td>{i.place}</td>
                      // </tr>
                      <tr key={"el" + idx}>
                        <td>{i.time}</td>
                        <td>
                          {i.description}
                          <br />
                          <div
                            style={{
                              fontSize: "smaller",
                              color: "var(--primary)",
                            }}
                          >
                            {i.place}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
          <h3>
            <a href="#exhibition">Zu den Ausstellungen</a>
          </h3>
        </Card>
      </PageContent>
    </>
  );
};
