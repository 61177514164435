import { Footer } from "../components/Footer";
import "./Map.scss";

export let Map: React.FC = () => {
  return (
    <>
      <div className="map-container">
        <iframe
          title="Interaktive Karte"
          src="https://www.google.com/maps/d/u/0/embed?mid=1m6uE4MFioFc-EQU2ApyDX6xrYFTWE6Q&ehbc=2E312F&hl=de"
          width="100%"
          height="100%"
        ></iframe>
      </div>
      <Footer></Footer>
    </>
  );
};
