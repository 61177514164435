import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import "./Header.scss";
import { ReactComponent as Logo } from "../icons/logo4.svg";
import { MenuPopup } from "./MenuPopup";

interface Props {
  entries: { [id: string]: string };
}
export let Header: React.FC<Props> = ({ entries }) => {
  let [scrollPosition, setScrollPosition] = useState(0);
  let requestRef = React.useRef<number>(-1);
  let animate = useCallback((position: number) => {
    if (requestRef.current !== undefined) {
      setScrollPosition(position);
    }
  }, []);
  let handleScroll = useCallback(() => {
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    let height = window.innerHeight;
    let dividerHeight = 180;
    let scrolled = winScroll / (height + dividerHeight);
    if (scrolled <= 4) {
      requestAnimationFrame(() => animate(scrolled));
    }
  }, [animate]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);


  let top = Math.max(1 - scrollPosition * 1.3, 0);
  let AppHeaderStyle: CSSProperties = {
    height: 100 * top + "%",
    top: Math.max(0, scrollPosition - 2) * 60 + "px",
  };
  let subtitleStyle: CSSProperties = {
    transform: `translateY(10px) scaleY(${top * 100}%)`,
  };

  let isTop = scrollPosition < 0.5;
  let isBottom = scrollPosition > 2.5 && scrollPosition < 3.5;
  let position = isTop ? "top" : isBottom ? "bottom" : "middle";
  if (scrollPosition>3.5){
    AppHeaderStyle.top="0px"
  }
  return (
    <header className="app-header" style={AppHeaderStyle}>
      <div
        className={"header-wrapper " + position}
      >
        <div
          className={
            "header " + position
          }
        >
          {/* <Logo /> */}
          <img src="./assets/img/logo.svg" alt="Logo" className="logo"></img>
        </div>
        <div className="subheader" style={subtitleStyle}>
          03 - 04.09.22 (10-18 Uhr)
        </div>
        {window.location.hash !== "#start" && window.location.hash !== "" && (
          <MenuPopup entries={entries} fixed={false} size="40px"></MenuPopup>
        )}
      </div>
    </header>
  );
};
