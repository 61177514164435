import gsap,{TimelineLite} from "gsap";
import { useCallback, useEffect, useRef, useState } from "react";
import "./MenuPopup.scss";
interface Props {
  entries: { [id: string]: string };
  size?: string;
  fixed?: boolean;
  startpage?: boolean;
}
export let MenuPopup: React.FC<Props> = ({ entries, size, fixed, startpage }) => {
  let [active, setActive] = useState<string | undefined>(entries[0]);
  let [visible, setVisible] = useState(false);
  let [rerender, forceRender] = useState(false);
  let onLocationChange = useCallback(() => {
    setActive(
      Object.keys(entries).find((e) => window.location.href.includes("#" + e))
    );
  }, [entries]);
  useEffect(() => {
    window.addEventListener("hashchange", onLocationChange);
    return () => {
      window.removeEventListener("hashchange", onLocationChange);
    };
  }, [onLocationChange]);
  useEffect(() => {
    if (
      rerender === false &&
      window.location.hash !== "" &&
      window.location.hash !== "start"
    ) {
      forceRender(!rerender);
      onLocationChange();
    }
  }, [forceRender, rerender, onLocationChange]);
  let toggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [setVisible, visible]);
  if (startpage&&(active!==undefined&&active!=="start")){
    return null
  }
    return (
      <>
        <MenuButton
          // className={"menu-button" + (visible ? " visible" : " hidden")}
          active={visible}
          fixed={fixed}
          onClick={toggleVisible}
          size={size}
        ></MenuButton>
        <div
          onClick={toggleVisible}
          className={"menu-popup" + (visible ? " visible" : " hidden")}
        >
          {Object.keys(entries).map((e, i) => (
            <MenuPopupEntry
              key={"entry" + i}
              hash={e}
              name={entries[e]}
              active={active}
              onClick={toggleVisible}
              smaller={["sponsors","impressum"].includes(e)}
            />
          ))}
        </div>
      </>
    );
};

interface EProps {
  hash: string;
  name: string;
  active?: string;
  smaller:boolean;
  
  onClick?(hash:string):void
}
let MenuPopupEntry: React.FC<EProps> = ({ hash, name, active,onClick, smaller }) => {
  let _onClick = useCallback(() => {
    window.location.assign("#" + hash);
    onClick&&onClick(hash);
  }, [hash, onClick]);
  return (
    <>
    {hash==="sponsors"?<div style={{height:"35px"}}></div>:null}
      <div
        onClick={_onClick}
        className={
          (hash === active || (active === undefined && hash === "start")
            ? "active"
            : "inactive")+(smaller?" smaller":"")
        }
      >
        {name}
      </div>
    </>
  );
};


interface BProps {
  active: boolean;
  onClick(): void;
  fixed?:boolean;
  size?:string;
}

let MenuButton: React.FC<BProps> = ({
  active,
  size = "50px",
  fixed = true,
  onClick,
}) => {
  const animation = useRef<TimelineLite>();
  const svg = useRef<SVGSVGElement>(null);

  useEffect(() => {
    let l1 = svg.current?.querySelector("#Line1");
    let l2 = svg.current?.querySelector("#Line2");
    let l3 = svg.current?.querySelector("#Line3");
    if (l1 && l2 && l3) {
      animation.current = gsap
        .timeline()
        .to(
          l1,
          {
            rotate: "45deg",
            x: 80,
            y: 40,
          },
          "start"
        )
        .to(
          l2,
          {
            scale: "0",
          },
          "start"
        )
        .to(
          l3,
          {
            rotate: "-45deg",
            x: 40,
            y: 0,
          },
          "start"
        );
    }

    return () => {
      animation.current?.kill();
    };
  }, []);

  useEffect(() => {
    if (!active) {
      animation.current?.reverse();
    } else {
      animation.current?.play();
    }
  }, [active]);

  return (
    <div
      className={
        "menu-button" +
        (fixed ? " fixed" : " relative") +
        (active ? " visible" : " hidden")
      }
    >
      <svg
        onClick={onClick}
        ref={svg}
        id="hamburger"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="-100 -100 712 712"
      >
        {/* <defs>
       <style>.cls-1{fill:#fff;}.cls-2{fill:#5dc199;}</style>
      </defs> */}
        <path
          id="Line1"
          className={"ham-line" + (active ? " active" : "")}
          d="M479.18,91.9H32.82a32.82,32.82,0,0,1,0-65.64H479.18a32.82,32.82,0,0,1,0,65.64Z"
        />
        <path
          id="Line2"
          className={"ham-line" + (active ? " active" : "")}
          d="M479.18,288.82H32.82a32.82,32.82,0,0,1,0-65.64H479.18a32.82,32.82,0,0,1,0,65.64Z"
        />
        <path
          id="Line3"
          className={"ham-line" + (active ? " active" : "")}
          d="M479.18,485.74H32.82a32.82,32.82,0,0,1,0-65.64H479.18a32.82,32.82,0,0,1,0,65.64Z"
        />
      </svg>
    </div>
  );
};