export type ExhibitionType = {
  id?: string;
  description?: string;
  artist: React.ReactNode;
  website?: string;
  info?: React.ReactNode;
};
export type PlacesType = {
  map_id: number;
  id: string;
  name: string;
  location: string;
};
export type LocationType = {
  website?: string;
};
export let EXHIBITIONS: ExhibitionType[] = [
  {
    id: "schulweg",
    description: "Wasserinstallation",
    artist: (
      <>
        <a href="https://www.paulludwigressl.com" target="blank">
          Paul Ludwig Reßl
        </a>{" "}
        und{" "}
        <a href="https://www.linaschobel.de" target="blank">
          Lina Schobel
        </a>
      </>
    ),
    // website: "https://www.linaschobel.de",
  },
  {
    id: "schmiede",
    description: "Malerei (Live)",
    artist: "Andrea R. Walla",
    website: "https://www.spielweltverlag.com",
    info: (
      <div>
        Geboren und aufgewachsen in Schongau. Wohnhaft in Bernbeuren in einem
        alten wiederaufgebauten Bauernhaus.
        <br />
        <br />
        Die Künstlerin sieht sich selbst als Illustratorin von Emotionen und
        nennt es Spielwelt.
        <br />
        <br />
        Ihre Werke zeigen Einblicke in diese faszinierende und facettenreiche
        innere Welt und laden ein, den eigenen Betrachtungswinkel für neue
        Erkenntnisse zu ändern.
        <br />
        <br />
        Im Laufe der Jahre hat sie eine einzigartige Technik entwickelt, bei der
        sich das Motiv erst während der Arbeit am Bild heraus kristallisiert.
        Diese Technik wird sie live an den Ausstellungstagen vorführen.
        <br />
        <br />
        Ihre eigenen Erkenntnisse veröffentlicht sie in Kurzgeschichten und
        Romanen, die sie neben den Originalen und Prints ihrer Werke im
        Selbstverlag vertreibt.
        <br />
        <br />
        Ihre Arbeiten befinden sich überwiegend im Privatbesitz. Öffentlich
        zugänglich ist beispielsweise die Aussegnungshalle am Waldfriedhof
        Schongau oder das ehemalige Standesamt in der Gemeinde Bernbeuren.
      </div>
    ),
  },
  {
    id: "wirt",
    description: "Malerei",
    artist: (
      <>
        Künstlergruppe <i>haus24</i>
      </>
    ),
    info: (
      <div>
        <h2>Urte Klatz-Schmuck</h2>
        Ich wurde 1953 in München geboren. Nach dem Abitur studierte ich in
        München an der LMU Japanologie, Kunstgeschichte und
        Theaterwissenschaften. Longe Zeit war die Malerei ein wichtiger
        Ausgleich zu meiner beruflichen Tätigkeit und heute gehört das Malen und
        Gestalten für mich zu meinem täglichen Leben. Das Bedürfnis seine
        Gedanken und Gefühle sichtbar zu machen, lässt sich auf vielerlei Weise
        umsetzen. Ob in der Literatur, der Musik oder der bildenden Kunst -
        tolle Werke der Künstler sind ein wichtiger Bestandteil unseres
        täglichen Lebens. <br />
        Für mich ist die Malerei das geeignete Medium, um meine Ideen zu
        visualisieren. Je nachdem, welche Idee ich gestalterisch umsetzen
        möchte, suche ich die entsprechenden Materialien aus. So male ich oft in
        Acryl, lock, Kunstharz, Plaka und bringe Strukturen in die Bilder
        mittels Stoff, Glas, Steinen, Modelliermasse, Sand oder Rinde. Je noch
        benötigtem Untergrund wird neben der Verwendung von einfachen Keilrahmen
        auch oftmals Stoff auf ausrangierte Holzplatten aufgezogen und als
        Malgrund präpariert. Bei der Verwirklichung meiner Ideen folge ich zwar
        stets dem gedanklichen Entwurf, lasse ober bei der Ausführung in Form,
        Farbe und Komposition meiner Intuition freien Lauf.
        <h2>Holger Gebhard</h2>
        Malen - meine Berufung
        <br /> Die Welt in leuchtenden Farben - so weisen die großen Bilder auf
        die Schönheit der Welt hin, fordern den Betrachter auf, inne zu holten,
        sich auf »das Gute im Universum« zu besinnen und so den Stress und die
        Hektik des Alltags zurückzulassen.
        <br /> 40 Jahre Berufserfahrung als Grafikdesigner, eine ausgereifte
        Technik und ein sicheres Gespür für Farben und Formen bilden die
        Grundlage - hinzu kommt das gewisse Etwas, die »Schwingungen«, die
        Emotionen des Moments, die sich auf das Gemalte niederschlagen und die
        jedes Bild zu etwas Einzigartigem machen.
        <br /> Die Formen und Farben der Bilder erzeugen auch beim Betrachter
        eine ganz besondere, individuelle Stimmung. Jeder entdeckt hier sein
        ganz eigenes Bild, das nur er selbst sehen und erkennen kann - und das
        jeden Tag aufs Neue.
        <h2>
          Martha Palecek{" "}
          <a href="https://www.martapalecek.com" style={{ fontSize: "small" }}>
            (Website)
          </a>
        </h2>
        Ich bin in Wien geboren,
        <br />
        als älteste Tochter in eine Großfamilie mit slawischen Wurzeln.
        <br />
        Ich bin Kind dieser Stadt
        <br />
        <br />
        Der Vater Auslogendekorateur, sein Bruder malte Stillleben, eine
        Schwester Bauernmalerei. Mutter brachte uns in die Theater! Und ließ
        mich ziehen.
        <br />
        <br />
        Nun, schon früh durchstreifte ich die Gassen,
        <br />
        Museen, Kirchen und Palais - und die
        <br />
        Praterauen.
        <br />
        A bisserl Stadt - a bisserl Land.
        <br />
        A bisserl Berg - a bisserl See.
        <br />
        Tag und Nacht.
        <br />
        Römer und Kelten.
        <br />
        Kaiser und Bettler.
        <br />
        Oper und Wurstelprater.
        <br />
        Spiritualität und Bigotterie.
        <br />
        Licht und Schotten.
        <br />
        und in ihrer Mitte der Mensch.
        <br />
        <br />
      </div>
    ),
  },
  // {
  //   id: "wirt",
  //   description: "Skulptur, Zeichnung",
  //   artist: "Anna Pfanzelt",
  //   website: "http://anna-pfanzelt.de",
  // },
  {
    id: "quelle",
    description: "Skulptur",
    artist: (
      <>
        <a href="https://www.instagram.com/centuckyfredchicken/" target="blank">
          Fred Ressl
        </a>{" "}
        und Marius Schölch
      </>
    ),
  },
  {
    id: "quelle",
    description: "Installation",
    artist: "Alexander Klein",
    website: "https://abklein.de/",
    info: (
      <div>
        Unter abklein macht Alexander Klein "Gestaltung für Menschen". Als
        Schreiner, Industriedesigner und Lebenskünstler kann er so zunächst
        selbst einen gesunden Alltag leben. Langfristig möchte er jedoch auch
        anderen helfen, sich sogar während der Arbeit ganzheitlich zu fordern
        und zu fördern.
        <br />
        <br />
        Werk:
        <br />
        Grenzen überschreiten - Perspektiven verändern - Unterschiede verstehen
        <br />
        Die Arbeit zeigt, dass die eigene Wahrnehmung bestimmt, was wir sehen.
        <br />- Und dass es nicht Die eine Wahrheit gibt.
      </div>
    ),
  },
  {
    id: "kulze",
    description: "Druck und Fotografie",
    artist: "Anna Neu",
    website: "https://instagram.com/analog.tschick",
  },
  {
    id: "proebstl",
    description: "Objekte",
    artist: "Anke Firlefanz",
    website: "https://www.anke-firlefanz.com",
  },
  {
    id: "wirt",
    artist: "Nicole Uslenghi",
  },
  {
    id: "wirt",
    artist: "Anna Wüller",
    description: "Zeichnung",
    website: "https://www.buchstaberei.de",
    info: (
      <div>
        Ich komme aus München, bin Polsterin und Künstlerin.
        <br />
        <br />
        Meine Kunst beschreibe ich als schwarze Linien auf Papier, Formen und
        Buchstaben, grafische Arbeiten, System und Zufall.
        <br />
      </div>
    ),
  },
  {
    id: "dorfplatz",
    description: "Skulptur",
    artist: "Wolfram Kulot",
  },
  {
    id: "beimfreibad",
    description: "Brücke",
    artist: (
      <>
        <a href="">Nik Haberstock</a> und{" "}
        <a href="https://www.instagram.com/Elias_Angerer/">Elias Angerer</a>
      </>
    ),
  },
  // {
  //   id: "rathauscafe",
  //   description: "Filz",
  //   artist: "Heidi Häringer",
  // },
  {
    id: "rathauscafe",
    description: "Fotografie",
    artist: "Nicole Kessler",
  },
  {
    id: "rathauscafe",
    description: "Fotografie",
    artist: "Anna Neu",
  },
  {
    id: "wirt",
    description: "Siebdruck",
    artist: "Peter Mayr und Endy Hupperich",
    website: "https://www.petrmayr.de",
  },
  {
    id: "schniederletzte",
    description: "Weidenobjekte",
    artist: "Anna Gast-Wendland",
  },
  {
    id: "fisch",
    description: "Holzskulptur",
    artist: "Josef Lang",
    website: "https://www.joseflang-bildhauer.de",
  },
  {
    id: "insel",
    description: "Installation",
    artist: "Cornelia Rapp",
    website: "https://www.corneliarapp.de",
  },
  {
    id: "wirt",
    description: "Musikinstrumente",
    artist: "Erwin Muschielok",
  },
  {
    id: "brs1.2",
    description: "Glas-Installation",
    artist: "Felix Bartesch",
    website: "https://www.instagram.com/fxmsbh/",
  },
  {
    id: "dorfbruecke",
    description: "Malerei",
    artist: "Swen Keyser",
    website: "https://swenkeyser.de",
  },
  {
    id: "brh1",
    description: "Schilder",
    artist: (
      <>
        <a href="https://franziskazimmermann.com" target="blank">
          Franziska Zimmermann
        </a>{" "}
        und{" "}
        <a href="https://www.instagram.com/centuckyfredchicken/" target="blank">
          Fred Ressl
        </a>
      </>
    ),
  },
  {
    id: "b17",
    description: "Lichtinstallation",
    artist: "Sophia Endt",
  },
  {
    id: "pfanne",
    description: "Malerei",
    artist: "Sophia Endt",
  },
  {
    id: "eschbach",
    description: "Objekt",
    artist: "Willi Brauer",
  },
  {
    id: "raiffeisen",
    description: "Fotografie",
    artist: (
      <>
        <a href="https://www.onestadler.de">Simone Stadler</a> und{" "}
        <a href="https://instagram.com/Glitzerfee_666">Kathrin Bild</a>
      </>
    ),
  },
  {
    id: "schmiede",
    description: "Skulptur",
    artist: "W. Brauer, K. Endt, A. Wüller",
  },
  {
    id: "feuerwehr",
    description: "Soundinstallation",
    artist: "Elias Angerer",
    website: "https://www.instagram.com/Elias_Angerer/",
  },
  {
    id: "welfengasse",
    description: "Holzskulptur",
    artist: "Anja-Nina Stiglmair",
    website: "https://www.instagram.com/sie.mag.holz/",
  },
  // {
  //   id: "wirt",
  //   description: "Film",
  //   artist: <i>Bewegliche Bilder</i>,
  // },
  {
    id: "wirt",
    description: "Malerei",
    artist: "Amalia Becker",
    website: "https://www.instagram.com/mali.malt/",
  },
  {
    id: "goldrausch",
    description: "Malerei",
    artist: "Amalia Becker",
    website: "https://www.instagram.com/mali.malt/",
  },
  {
    id: "wirt",
    description: "Malerei",
    artist: "Paul Ressl",
  },
  {
    id: "wirt",
    description: "Skulpturen, Hinterglasmalerei",
    artist: "Stefan Walter",
  },
  {
    id: "brh2",
    description: "Boandlkramer-Bridge",
    artist: "Stefan Walter",
  },
  {
    artist: "Egon Stöckle",
    description: "Skulptur",
    website: "https://www.egon-stoeckle.com",
    id: "sitzplatz",
  },
  {
    description: "Skulptur",
    artist: "Angela Prade",
    website: "https://www.angelaprade.de",
    id: "dorfbruecke",
  },
  {
    description: "Holzbrücke",
    artist: "Marcel Neu",
    id: "brs1",
  },
  {
    id: "wirt",
    artist: "Julia Hiemer",
    description: "Skulpturen",
    website: "https://juliahiemer.de",
  },
];
export let PLACES: PlacesType[] = [
  { map_id: 0, id: "quelle", name: "Schönachquellen", location: "Schwabsoien" },
  {
    map_id: 1,
    id: "schmiede",
    name: "Hammerschmiede",
    location: "Schwabsoien",
  },
  { map_id: 2, id: "proebstl", name: "Pröbstl-Mühle", location: "Schwabsoien" },
  { map_id: 3, id: "dorfbruecke", name: "Dorfbrücke", location: "Schwabbruck" },
  {
    map_id: 3,
    id: "raiffeisen",
    name: "Altes Raiffeisenlager",
    location: "Schwabbruck",
  },
  {
    map_id: 4,
    id: "eschbach",
    name: "Brücke am Eschbach",
    location: "Schwabbruck",
  },
  {
    map_id: 4,
    id: "pfanne",
    name: "Steg bei Endt's",
    location: "Schwabbruck",
  },
  {
    map_id: 5,
    id: "beimfreibad",
    name: "Beim Freibad",
    location: "Altenstadt",
  },
  { map_id: 5, id: "altemuehle", name: "Alte Mühle", location: "Altenstadt" },
  {
    map_id: 5,
    id: "schulweg",
    name: "Brücke am Schulweg",
    location: "Altenstadt",
  },
  { map_id: 6, id: "dorfplatz", name: "Rathausplatz", location: "Altenstadt" },
  {
    map_id: 6,
    id: "rathauscafe",
    name: "Altes Rathauscafé",
    location: "Altenstadt",
  },
  {
    map_id: 7,
    id: "feuerwehr",
    name: "Histor. Feuerwehrhaus",
    location: "Altenstadt",
  },
  { map_id: 8, id: "goldrausch", name: "Goldrausch", location: "Altenstadt" },
  {
    map_id: 8,
    id: "bra",
    name: "Brücke am Angerweg",
    location: "Altenstadt",
  },
  {
    map_id: 9,
    id: "welfengasse",
    name: "Brücke an der Welfengasse",
    location: "Altenstadt",
  },
  {
    map_id: 10,
    id: "brs1",
    name: "Zwischen Altenstadt und Schwabniederhofen",
    location: "Schwabniederhofen",
  },
  {
    map_id: 10,
    id: "brs1.2",
    name: "Brücke am Ortseingang",
    location: "Schwabniederhofen",
  },
  { map_id: 11, id: "brs2", name: "Brücke", location: "Schwabniederhofen" },
  { map_id: 12, id: "ministeg", name: "Steg", location: "Schwabniederhofen" },
  {
    map_id: 12,
    id: "frieden",
    name: "Brücke an der Friedenslinde",
    location: "Schwabniederhofen",
  },
  {
    map_id: 13,
    id: "kulze",
    name: "Kulturzentrum",
    location: "Schwabniederhofen",
  },
  {
    map_id: 14,
    id: "fisch",
    name: "Wehr bei den Fischtreppen",
    location: "Schwabniederhofen",
  },
  {
    map_id: 14,
    id: "insel",
    name: "Auf der Insel",
    location: "Schwabniederhofen",
  },
  {
    map_id: 14,
    id: "schniederletzte",
    name: "Letzte Brücke in Schwabniederhofen",
    location: "Schwabniederhofen",
  },
  {
    map_id: 15,
    id: "altemuehle",
    name: "Alte Mühle",
    location: "Hohenfurch",
  },
  {
    map_id: 15,
    id: "brh1",
    name: "Brücke am Talweg",
    location: "Hohenfurch",
  },
  {
    map_id: 16,
    id: "brh2",
    name: "Brücke am Spielplatz",
    location: "Hohenfurch",
  },
  {
    map_id: 17,
    id: "b17",
    name: "Unterführung B17",
    location: "Hohenfurch",
  },
  {
    map_id: 18,
    id: "wirt",
    name: "Westermayr (Alte Wirtschaft)",
    location: "Hohenfurch",
  },
  {
    map_id: 18,
    id: "sitzplatz",
    name: "Steg ggü. vom Bräuweg",
    location: "Hohenfurch",
  },
];
export let LOCATIONS: { [location: string]: LocationType } = {
  Schwabsoien: { website: "https://www.schwabsoien.de" },
  Schwabbruck: { website: "https://schwabbruck.auerbergland.de" },
  Altenstadt: { website: "https://www.altenstadt-obb.de" },
  Schwabniederhofen: { website: "https://www.altenstadt-obb.de" },
  Hohenfurch: { website: "https://www.hohenfurch.de" },
};
