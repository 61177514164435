import "./Card.scss"


interface Props {
  title?: React.ReactNode;
  children: React.ReactNode;
  className?:string;
  onTitleClick?():void
}
  export let Card: React.FC<Props> = ({ className,children, title, onTitleClick }) => {
    return (
      <div className={"card "+className}>
        {title && (
          <div
            className={"card-title " + (onTitleClick ? "button" : "")}
            onClick={onTitleClick}
          >
            {title}
          </div>
        )}
        <div className="card-content">{children}</div>
      </div>
    );
  };