import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import { ReactComponent as MapSchematic } from "../icons/schematic.svg";
import "./Exhibition.scss";
import {
  EXHIBITIONS,
  PLACES,
  ExhibitionType,
  LOCATIONS,
  LocationType,
  PlacesType,
} from "./Exhibitors";
import { PageTitle } from "../components/PageTitle";
import { PageContent } from "../components/PageContent";
import { Card } from "../components/Card";

export let Exhibition: React.FC = () => {
  let [active, setActive] = useState<string | undefined>("");
  let [visible, setVisible] = useState(true);
  let schematicRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    let markers = schematicRef.current?.children[4].children;
    if (markers) {
      for (let id = 0; id < markers.length; id++) {
        markers[id].addEventListener("mouseenter", () =>
          setActive(getPlaceByMapID(id)?.id)
        );
      }
    }
  }, []);
  useEffect(() => {
    let markers = schematicRef.current?.children[4].children;
    if (markers) {
      for (let id = 0; id < markers.length; id++) {
        if (id === getPlaceByID(active)?.map_id) {
          markers[id].setAttribute("class", "active");
        } else {
          markers[id].setAttribute("class", "inactive");
        }
      }
    }
  }, [active]);

  let valid_locations = EXHIBITIONS.map((e) => getPlace(e)?.location).filter(
    (v, idx, all) => all.indexOf(v) === idx
  );
  //@ts-ignore
  valid_locations = [...Object.keys(LOCATIONS), undefined].filter(
    //@ts-ignore
    (v) => valid_locations.indexOf(v) >= 0
  );
  let groups = valid_locations.map((v) =>
    EXHIBITIONS.filter((e) => getPlace(e)?.location === v)
  );

  // let IDs = EXHIBITIONS.map((e)=>e.id).filter((e,i,all)=>all.indexOf(e)===i).sort((e,a)=>(e?e:"")>(a?a:"")?1:-1)
  let assigned = groups.flat();
  if (assigned.length !== EXHIBITIONS.length) {
    let missing = EXHIBITIONS.filter((v) => assigned.indexOf(v) < 0);
    console.error(assigned.length, EXHIBITIONS.length);
    console.info(missing);
  }

  let hideContent = useCallback(() => {
    // setVisible(false);
  }, [setVisible]);
  let showContent = useCallback(() => {
    setVisible(true);
  }, [setVisible]);
  useEffect(() => {
    window.addEventListener("mouseup", showContent);

    return () => {
      window.removeEventListener("mouseup", showContent);
    };
  }, [showContent]);

  return (
    <>
      <PageTitle>
        Ausstellungen
        {/* <div className="suche">
          <a href="#impressum">
            Lust teilzunehmen? Melde dich bis zum 30.Juni!
          </a>
        </div> */}
      </PageTitle>
      <PageContent className={visible ? "visible" : "hidden"}>
        {groups.map((g, idx) => (
          <LocationGroup
            key={"location" + idx}
            location={g}
            name={valid_locations[idx]}
            info={LOCATIONS[valid_locations[idx]]}
            active={active}
            activate={setActive}
            onPlaceClick={hideContent}
          />
        ))}
        <div style={{ height: "60px", width: "100%" }}></div>
      </PageContent>
      <div className="schematic-container">
        <MapSchematic ref={schematicRef}></MapSchematic>
      </div>
    </>
  );
};

interface EProps {
  exhibition: ExhibitionType;
  website?: string;
}
interface EIProps {
  exhibition: ExhibitionType;
  visible: boolean;
  onClose(): void;
}

interface LProps {
  name?: string;
  location: ExhibitionType[];
  active?: string;
  activate(id?: string): void;
  info?: LocationType;
  onPlaceClick(): void;
}
interface PProps {
  name?: string;
  location: ExhibitionType[];
  place?: PlacesType;
  active?: string;
  activate(id?: string): void;
  onPlaceClick(): void;
}

let LocationGroup: React.FC<LProps> = ({
  active,
  activate,
  location,
  info,
  name,
  onPlaceClick,
}) => {
  let onTitleClick = useCallback(() => {
    if (info) {
      window.open(info.website, "__blank__");
    }
  }, [info]);
  let places = location
    .sort((e, f) => (String(e.artist) > String(f.artist) ? 1 : -1))
    .sort((e, f) => (getPlace(e)?.map_id > getPlace(f)?.map_id ? 1 : -1))
    .filter((e, i, all) => all.indexOf(e) === i)
    .map((e) => e.id)
    .filter((e, i, all) => all.indexOf(e) === i);

  return (
    <Card
      title={name ? name : "?"}
      className="location-card"
      onTitleClick={info ? onTitleClick : undefined}
    >
      {places.map((p, idx) => (
        <PlaceGroup
          key={(name ? name : "") + idx + "-" + p}
          location={location.filter((v) => v.id === p)}
          active={active}
          place={getPlaceByID(p)}
          activate={activate}
          onPlaceClick={onPlaceClick}
        />
      ))}
    </Card>
  );
};

let PlaceGroup: React.FC<PProps> = ({
  active,
  activate,
  location,
  place,
  name,
  onPlaceClick,
}) => {
  // let onClick = useCallback(
  //   (idx: number) => {
  //     if (idx) {
  //       window.open(location[idx].website, "__blank__");
  //     }
  //   },
  //   [location]
  // );

  let setActive = useCallback(() => activate(place?.id), [place?.id, activate]);

  return (
    <div
      className={
        "place " +
        (active === place?.id && place?.id !== undefined
          ? "active "
          : "inactive ")
      }
      onMouseEnter={setActive}
      onMouseDown={onPlaceClick}
    >
      {place && <div className="place-title">{place.name}</div>}
      <div className="place-content">
        <table>
          <tbody>
            {location
              .sort((e, f) => (String(e.artist) > String(f.artist) ? 1 : -1))
              // .sort((e, f) =>
              //   getPlace(e)?.map_id > getPlace(f)?.map_id ? 1 : -1
              // )
              .map((e, idx) => (
                <ExhibitionElement
                  key={(name ? name : "") + idx + "-" + e.id}
                  exhibition={e}
                  website={e.website}
                />
              ))}
          </tbody>
        </table>
        {/* <tr className="exhibition-end">Und weitere ...</tr> */}
      </div>
    </div>
  );
};

const BodyPortal = ({ component }: any) =>
// @ts-ignore
  ReactDOM.createPortal(component, document.querySelector("#root"));

let ExhibitionElement: React.FC<EProps> = ({ exhibition, website }) => {
  let [infoVisible, setInfoVisible] = useState(false);
  let onClick = useCallback(() => {
    if (exhibition.info) {
      setInfoVisible(true);
    } else if (website) {
      window.open(website, "__blank__");
    }
  }, [website, setInfoVisible, exhibition.info]);
  let [active, setActive] = useState(false);
  let activate = useCallback(() => {
    setActive(true);
  }, []);
  let deactivate = useCallback(() => {
    setActive(false);
  }, []);
  let hideInfo = useCallback(() => {
    setInfoVisible(false);
  }, []);
  let showSideBar = website === undefined && exhibition.info === undefined;
  // let _onClick = useCallback(() => onClick && onClick(idx), [idx, onClick]);
  return (
    <>
      <BodyPortal
        component={
          <ExhibitionInfoElement
            visible={infoVisible}
            exhibition={exhibition}
            onClose={hideInfo}
          />
        }
      />
      <tr
        key={exhibition.id}
        onClick={onClick}
        onMouseEnter={showSideBar ? undefined : activate}
        onMouseLeave={showSideBar ? undefined : deactivate}
        className={
          "exhibition " +
          (active ? "active " : "inactive ") +
          (showSideBar ? "" : "button")
        }
      >
        <td>
          {exhibition.artist}{" "}
          {exhibition.description ? "| " + exhibition.description : ""}
        </td>
      </tr>
    </>
  );
};

let stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};
let ExhibitionInfoElement: React.FC<EIProps> = ({
  exhibition,
  visible,
  onClose,
}) => {
  let onClick = useCallback(() => {
    if (exhibition.website) {
      window.open(exhibition.website, "__blank__");
    }
  }, [exhibition.website]);
  
  return (
    <div
      className={"exhibition-info-wrapper " + (visible ? "visible" : "hidden")}
      onClick={onClose}
    >
      <div
        className={"exhibition-info " + (visible ? "visible" : "hidden")}
        onClick={stopPropagation}
      >
        <div className="exhibition-info-header">
          <div>{exhibition.artist}</div>
          <div className="close-button" onClick={onClose}>
            X
          </div>
        </div>
        <div className="exhibition-info-content">{exhibition.info}</div>
        {exhibition.website !== undefined && (
          <div className="website-button" onClick={onClick}>
            Zur Website
          </div>
        )}
      </div>
    </div>
  );
};

let getPlace = (ex: ExhibitionType) => {
  return PLACES.find((p) => p.id === ex.id) as PlacesType;
};

let getPlaceByMapID = (id: number) => {
  return PLACES.find((p) => p.map_id === id);
};
let getPlaceByID = (id?: string) => {
  return PLACES.find((p) => p.id === id);
};
