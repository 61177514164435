import { Card } from "../components/Card";
import { PageContent } from "../components/PageContent";
import { PageTitle } from "../components/PageTitle";
import "./Impressum.scss";

export let Impressum: React.FC = () => {
  return (
    <>
    {/* <div style={{height:"100px",width:"100%"}}></div> */}
      <PageTitle>Impressum</PageTitle>
      <PageContent>
        <Card className="impressum-card">
          <p>
            Sophia Endt
            <br />
            Schongauerstraße 19
            <br />
            81377 München
          </p>
          <p>
            Kontakt:
            <br />
            Telefon: 0173 1921902
            <br />
            E-Mail: <a href="mailto:sophiaendt@gmx.net">sophiaendt@gmx.net</a>
            <br />
            Website:{" "}
            <a href="https://www.bruecken-verbinden.de">
              www.bruecken-verbinden.de
            </a>
          </p>
        </Card>
      </PageContent>
    </>
  );
};
