import { useCallback, useEffect, useRef, useState } from "react";
import "./Menu.scss";
interface Props {
  entries: string[];
}
export let Menu: React.FC<Props> = ({ entries }) => {
  let [active, setActive] = useState<string | undefined>(entries[0]);
  let [rerender, forceRender] = useState(false);
  let onLocationChange = useCallback(() => {
    console.log(window.location.hash);
    setActive(entries.find((e) => window.location.hash.includes(e)));
  }, [entries]);
  useEffect(() => {
    window.addEventListener("hashchange", onLocationChange);
    return () => {
      window.removeEventListener("hashchange", onLocationChange);
    };
  }, [onLocationChange]);
  useEffect(() => {
    if (
      rerender === false &&
      window.location.hash !== "" &&
      window.location.hash !== "start"
    ) {
      forceRender(!rerender);
      onLocationChange()
    }
  }, [forceRender, rerender,onLocationChange]);

  // let onClick = useCallback((e: MouseEvent) => {
  //   let yPos = e.clientY / window.innerHeight;
  //   let margin = 0.1;
  //   if (yPos > 1 - margin) {
  //     goTo(entries, 1);
  //   }else if (yPos<margin){
  //     goTo(entries,-1)
  //   }
  // }, [entries]);
  // useEffect(() => {
  //   window.addEventListener("click", onClick);
  //   return () => window.removeEventListener("click", onClick);
  // }, [onClick]);
  // let isActive = (e: string) => window.location.href.includes("#" + e);

  let scrollDebounce = useRef<boolean>(false);
  let onScroll = useCallback(
    (e: WheelEvent) => {
      if (scrollDebounce.current || !window.location.href.includes("#start")) {
        return;
      }
      scrollDebounce.current = true;
      setActive(goTo(entries, e.deltaY));
      requestAnimationFrame(() =>
        window.setTimeout(() => (scrollDebounce.current = false), 500)
      );
    },
    [entries]
  );
  let onMenuScroll = useCallback(
    (e: React.WheelEvent) => {
      if (scrollDebounce.current) {
        return;
      }
      scrollDebounce.current = true;
      setActive(goTo(entries, e.deltaY));
      requestAnimationFrame(() =>
        window.setTimeout(() => (scrollDebounce.current = false), 500)
      );
    },
    [entries]
  );

  let touchY = useRef<number>();
  let touchMoveHandler = useCallback(
    (e: TouchEvent) => {
      if (touchY.current !== undefined) {
        let dy = touchY.current - e.touches[0].clientY;
        let dyRel = dy / window.innerHeight;
        if (
          Math.abs(dyRel) > 0.3 &&
          Math.abs(dy) > Math.min(400, window.innerHeight * 0.8)
        ) {
          setActive(goTo(entries, dy));
          touchY.current = undefined;
        }
      }
    },
    [entries]
  );
  let touchStartHandler = useCallback((e: TouchEvent) => {
    touchY.current = e.touches[0].clientY;
  }, []);
  let touchEndHandler = useCallback((e: TouchEvent) => {
    touchY.current = undefined;
  }, []);
  let onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "ArrowUp") {
        setActive(goTo(entries, -1));
      } else if (e.code === "ArrowDown") {
        setActive(goTo(entries, 1));
      }
    },
    [entries]
  );
  useEffect(() => {
    // window.addEventListener("wheel", onScroll);
    window.addEventListener("keydown", onKeyDown);
    // window.addEventListener("touchstart", touchStartHandler);
    // window.addEventListener("touchmove", touchMoveHandler);
    // window.addEventListener("touchend", touchEndHandler);
    return () => {
      // window.removeEventListener("wheel", onScroll);
      window.removeEventListener("keydown", onKeyDown);
      // window.removeEventListener("touchstart", touchStartHandler);
      // window.removeEventListener("touchmove", touchMoveHandler);
      // window.removeEventListener("touchend", touchEndHandler);
    };
  }, [
    onScroll,
    touchEndHandler,
    touchMoveHandler,
    touchStartHandler,
    onKeyDown,
  ]);
  let browser=detectBrowser()
  return (
    <div
      className={
        "menu" +
        (active !== undefined && active !== "start" ? " under-header" : "")
      }
      onWheel={onMenuScroll}
    >
      {entries.map((e, i) => (
        <>
          {browser !== "Firefox" ? (
            <a
              href={"#" + e}
              key={"entry" + i}
              className={
                e === active || (active === "" && e === "start")
                  ? "active"
                  : "inactive"
              }
            >
              {" "}
            </a>
          ) : (
            <div
              key={"entry" + i}
              className={
                e === active || (active === "" && e === "start")
                  ? "active"
                  : "inactive"
              }
            >
              {" "}
            </div>
          )}
        </>
      ))}
    </div>
  );
};

let goTo = (pages: string[], dir = 1) => {
  for (let i = 0; i < pages.length; i++) {
    if (window.location.href.includes("#" + pages[i])) {
      let idx = i;
      if (dir > 0) {
        idx = i + 1;
      } else {
        idx = i - 1;
      }
      // idx = Math.min(Math.max(0, idx), pages.length - 1);
      if (idx >= pages.length || idx < 0) {
        return;
      }
      // window.location.assign("/#" + pages[idx]);
      window.history.pushState(null, "", "/#" + pages[idx]);
      const page = document.getElementById(pages[idx]);
      if (page) {
        const element = page.getBoundingClientRect().top + window.scrollY;
        window.scroll({
          top: element,
          behavior: "smooth",
        });
      }
      return pages[idx];
      // page?.scrollIntoView({ behavior: "auto" });
      // console.log("goto "+pages[idx])
      // requestAnimationFrame(() => window.location.assign("/#" + pages[idx]));
      // requestAnimationFrame(() =>
      //   window.setTimeout(() => window.location.assign("/#" + pages[idx]), 500)
      // );
    }
  }
};


function detectBrowser() {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else {
    return "Unknown";
  }
} 